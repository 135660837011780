import { trendArrowImages } from '../../../utils/dashboardRenderer';
import { format } from 'date-fns';
const moment = require('moment');

const _ = require('lodash');
const startOfWeek = require('date-fns/start_of_week');
const startOfMonth = require('date-fns/start_of_month');
const startOfQuarter = require('date-fns/start_of_quarter');
const endOfWeek = require('date-fns/end_of_week');
const endOfMonth = require('date-fns/end_of_month');
const endOfQuarter = require('date-fns/end_of_quarter');
const getMonth = require('date-fns/get_month');

const lesserThanOne = "<1";
const typeNumber = "number";

const clientFunctions = {
    cleaningCycles: {
        transformResponse: ({ _clientResponse }) => {

            let lastDate = _clientResponse?.data?.data?.previous?.[0]?.deviceTimestamp ? moment(_clientResponse.data.data.previous[0].deviceTimestamp).format("MMMM D,YYYY HH:mm A") : "NA"
            return {
                current: _clientResponse.data.data.current,
                previous: lastDate
            }
        }
    },
    avgProductCount: {
        transformResponse: ({ _clientResponse, _widgetScope }) => {
            const current = _clientResponse?.data?.data?.current ? _clientResponse.data.data.current : 0;
            const previous = _clientResponse?.data?.data?.previous ? _clientResponse.data.data.previous : 0;
            const days = _widgetScope.dateFilterDays

            let currentResult = days > 0 ? Math.round(Number(current / days)) : 0
            let prevResult = days > 0 ? Math.round(Number(previous / days)) : 0

            return {
                current: (currentResult < 1 && currentResult > 0) ? '<1' : currentResult,
                previous: (prevResult < 1 && prevResult > 0) ? '<1' : prevResult,
                currentValue: currentResult,
                previousValue: prevResult
            }
        }
    },
    peakHour: {
        transformResponse: ({ _clientResponse }) => {
            let productData = _clientResponse?.data?.data?.productData || [];

            if (productData.length === 0) {
                return { chartData: { data: [], subTitleMessage: "0", setMaxPrecision: false } };
            }
            productData.map(item => {
                item.products = item.data.reduce((total, product) => total + product.count,0)
            })
            const is24Hours = localStorage.getItem("timeFormat") === "24";
            const hours24 = Array.from({ length: 24 }, (_, i) => { return `${i}-${i + 1}` });
            const hours12 = Array.from({ length: 24 }, (_, i) => { return (i > 12) ? `${i - 12}-${i - 11} pm` : (i === 12) ? `12-1 pm` : `${i}-${i + 1} am` });

            const xAxis = {
                hourly24: hours24,
                hourly12: hours12
            }
            if (is24Hours) {
                productData = productData.map(item => {
                    item.hour = item.hour + 1;
                    item.hour = (item.hour === 24 ? "23-24" : `${item.hour - 1}-${item.hour}`);
                    return {
                        "x": item.hour,
                        "y": item.products,
                        "alpha": 1
                    };
                });
            } else {
                productData = productData.map(item => {
                    item.hour = item.hour + 1;
                    item.hour = (item.hour === 12 ? "11-12 am" : item.hour === 24 ? "11-12 pm" : item.hour > 12 ? item.hour === 13 ? "12-1 pm" : `${item.hour - 13}-${item.hour - 12} pm` : `${item.hour - 1}-${item.hour} am`);
                    item.alpha = 1;
                    return {
                        "x": item.hour,
                        "y": item.products,
                        "alpha": 1
                    };
                });
            }

            let dataSet = [];
            let baseX = is24Hours ? xAxis.hourly24 : xAxis.hourly12;
            dataSet = baseX.map(item => {
                return {
                    x: item,
                    y: productData.find(res => res.x == item)?.y || 0,
                    alpha: 1
                }
            })
            return {
                chartData: {
                    data: dataSet,
                    setMaxPrecision: true,
                }
            };
        }
    },
    topTenSoldProducts: {
        transformResponse: ({ _clientResponse, _widgetScope }) => {
            // Set Columns
            let column = [
                {
                    "title": "#",
                    "key": "",
                    "sort": false,
                    "width": "4%",
                    "type": "AutoIncrement"
                },
                {
                    "title": "",
                    "key": "color",
                    "sort": false,
                    "type": "Color",
                    "width": "8%",
                    "textAlign": "center"
                },
                {
                    "title": "Product",
                    "key": "productID",
                    "sort": true,
                    "width": "64%",
                    "textAlign": "left"
                },
                {
                    "title": "Trend",
                    "key": "trend",
                    "sort": false,
                    "width": "12%",
                    "type": "Image",
                    "textAlign": "center"
                },
                {
                    "title": "Count",
                    "key": "counts",
                    "sort": true,
                    "width": "12%",
                    "textAlign": "center"
                }
            ]
            if (_widgetScope?.dateFilterValue?.value === "Custom") {
                column = column.filter(data => data.key !== "trend")
            }
            // Define product colors (replace with your actual colors)
            const productColors = ["#ff97cd", "#0083b3", "#7192f1", "#c10b6d", "#414548", "#eacd61", "#59a9ed", "#00eabf", "#ff3b84", "#e08e53"];

            const current = _clientResponse?.data?.data?.current?.length ? _clientResponse.data.data.current : [];
            // Transform the data
            const transformedData = current.map((currentItem, indx) => {
                // Get color based on product
                const color = productColors[indx]; // Default to black if color not found

                // Find corresponding item in previous array (if available)
                const previousItem = _clientResponse?.data?.data?.previous?.find((prevItem) => prevItem.product === currentItem.product) || 0;

                // Calculate trend
                const trend = previousItem ? (currentItem.count === previousItem.count ? 0 : currentItem.count > previousItem.count ? 1 : -1) : 0;

                // Create the new object
                return {
                    productID: currentItem.product,
                    counts: currentItem.count,
                    trend: trendArrowImages[trend === 1 ? "high" : trend === -1 ? "low" : ""],
                    color,
                    order: currentItem.count
                };
            });
            const sortedTransformData = transformedData.sort((a, b) => a.counts - b);
            return {
                data: {
                    value: sortedTransformData,
                    columns: column
                }
            }
        }
    },

    numberOfProductsSold: {
        transformResponse: async ({ _clientResponse, _widgetScope }) => {

            // Set Columns
            const columns = await getColumns(_widgetScope);

            // Set Data
            const products = await getProducts(_clientResponse, _widgetScope, columns)
            return {
                data: {
                    products,
                    columns
                }
            }
        }
    },

    spaceInformation: {
        transformResponse: async ({ _clientResponse }) => {
            let returnObj = {
                usedSpace: {
                    size: "",
                    percentage: 0
                },
                availableSpace: {
                    size: "",
                    percentage: 100
                }

            }
            let usedSize = _clientResponse?.data?.data?.shadow?.document?.deviceStatusInformation?.spaceDetails?.usedSpace?.value || _clientResponse?.data?.data?.shadow?.document?.usedSpace || '';
            let availableSize = _clientResponse?.data?.data?.shadow?.document?.deviceStatusInformation?.spaceDetails?.availableSpace?.value || _clientResponse?.data?.data?.shadow?.document?.availableSpace || '';
            console.log("usedSize: ", usedSize)
            console.log("availableSize: ", availableSize)
            if(usedSize && availableSize){
                let usedSizeInt = parseInt(usedSize);
                let availableSizeInt = parseInt(availableSize);
                if(usedSizeInt && availableSizeInt){
                    let totalSize = usedSizeInt + availableSizeInt;
                    returnObj.usedSpace.size = usedSizeInt;
                    returnObj.usedSpace.percentage = (usedSizeInt/totalSize) * 100;
                    returnObj.availableSpace.size = availableSizeInt;
                    returnObj.availableSpace.percentage = (availableSizeInt/totalSize) * 100;
                }
            }
            console.log("returnObj: ", returnObj)
            return {
                data: returnObj
            }
        }
    }

}

const getColumns = async (_widgetScope) => {
    const start = [{
        "title": "KC0169",
        "type": "AutoIncrement",
        "textAlign": "center"
    },
    {
        "title": "KC0170",
        "key": "product",
        "width": "50%"
    }]
    const end = [{
        "title": "KC0171",
        "key": "trend",
        "type": "Image",
        "textAlign": "center"
    },
    {
        "title": "KC0172",
        "key": "count",
        "textAlign": "center"
    }]
    let middle = []
    if (_widgetScope?.dateFilterValue?.value === 'Day') {
        middle = [{
            "title": "Day",
            "key": "day",
            "textAlign": "center"
        }]
    } else if (_widgetScope?.dateFilterValue?.value === 'Week') {
        middle = [{
            "title": "Day1",
            "key": "day1",
            "textAlign": "center"
        }, {
            "title": "Day2",
            "key": "day2",
            "textAlign": "center"
        }, {
            "title": "Day3",
            "key": "day3",
            "textAlign": "center"
        }, {
            "title": "Day4",
            "key": "day4",
            "textAlign": "center"
        }, {
            "title": "Day5",
            "key": "day5",
            "textAlign": "center"
        }, {
            "title": "Day6",
            "key": "day6",
            "textAlign": "center"
        }, {
            "title": "Day7",
            "key": "day7",
            "textAlign": "center"
        }
        ]
    } else if (_widgetScope?.dateFilterValue?.value === 'Month') {
        middle = [{
            "title": "Week1",
            "key": "week1",
            "textAlign": "center"
        }, {
            "title": "Week2",
            "key": "week2",
            "textAlign": "center"
        }, {
            "title": "Week3",
            "key": "week3",
            "textAlign": "center"
        }, {
            "title": "Week4",
            "key": "week4",
            "textAlign": "center"
        }, {
            "title": "Week5",
            "key": "week5",
            "textAlign": "center"
        }, {
            "title": "Week6",
            "key": "week6",
            "textAlign": "center"
        }]
    } else if (_widgetScope?.dateFilterValue?.value === 'Quarter') {
        middle = [{
            "title": "Month1",
            "key": "month1",
            "textAlign": "center"
        }, {
            "title": "Month2",
            "key": "month2",
            "textAlign": "center"
        }, {
            "title": "Month3",
            "key": "month3",
            "textAlign": "center"
        }]
    } else if (_widgetScope?.dateFilterValue?.value === 'Year') {
        middle = [{
            "title": "Q1",
            "key": "q1",
            "textAlign": "center"
        }, {
            "title": "Q2",
            "key": "q2",
            "textAlign": "center"
        }, {
            "title": "Q3",
            "key": "q3",
            "textAlign": "center"
        }, {
            "title": "Q4",
            "key": "q4",
            "textAlign": "center"
        }]
    }
    const columns = [...start, ...middle, ...end]
    return columns
}

const getProducts = async (_clientResponse, _widgetScope, columns) => {

    let data = _clientResponse?.data?.data?.products
    let previous = _clientResponse?.data?.data?.previous

    let result = []

    if (data.length) {

        // Group By Product
        let groupData = _.groupBy(data, 'product')

        if (_widgetScope?.dateFilterValue?.value === "Day") {
            for (let i = 0; i < Object.keys(groupData).length; i++) {
                let oneData = groupData[Object.keys(groupData)[i]]
                let prevCount = previous.find(prevData => prevData.product === oneData[0].product)
                let trend = prevCount?.count ? (oneData[0].count === prevCount.count ? 0 : oneData[0].count > prevCount.count ? 1 : -1) : 0;
                let oneResult = {
                    product: oneData[0].product,
                    day: oneData[0].count,
                    count: oneData[0].count,
                    trend: trendArrowImages[trend === 1 ? "high" : trend === -1 ? "low" : ""]
                }
                result.push(oneResult)
            }
        }
        if (_widgetScope?.dateFilterValue?.value === "Week") {

            for (let i = 0; i < Object.keys(groupData).length; i++) {
                let oneData = groupData[Object.keys(groupData)[i]]
                let oneResult = {
                    product: oneData[0].product,
                    count: 0
                }
                const weeks = Array(7).fill({})
                let weekDate = moment(_widgetScope.dateFilterValueFormatted.fromDate)
                weeks.forEach(async (w, index) => {
                    let count = oneData.find(check => moment(check.date).isSame(weekDate))
                    count = count?.count || 0
                    oneResult['day' + (index + 1)] = count
                    oneResult.count = oneResult.count + count
                    weekDate = moment(weekDate).add(1, 'day')
                })
                let prevCount = previous.find(prevData => prevData.product === oneData[0].product)
                let trend = prevCount?.count ? (oneResult.count === prevCount.count ? 0 : oneResult.count > prevCount.count ? 1 : -1) : 0;
                oneResult.trend = trendArrowImages[trend === 1 ? "high" : trend === -1 ? "low" : ""]
                result.push(oneResult)
            }
        }
        if (_widgetScope?.dateFilterValue?.value === "Month") {
            for (let i = 0; i < Object.keys(groupData).length; i++) {
                let oneData = groupData[Object.keys(groupData)[i]]
                let oneResult = {
                    product: oneData[0].product,
                    count: 0
                }
                const weeks = Array(6).fill({})
                let weekNumber = moment(_widgetScope.dateFilterValueFormatted.fromDate).week()
                weeks.forEach(async (w, index) => {
                    let count = oneData.find(check => check.date == weekNumber)
                    count = count?.count || 0
                    oneResult['week' + (index + 1)] = count
                    oneResult.count = oneResult.count + count
                    weekNumber = weekNumber + 1
                })
                let prevCount = previous.find(prevData => prevData.product === oneData[0].product)
                let trend = prevCount?.count ? (oneResult.count === prevCount.count ? 0 : oneResult.count > prevCount.count ? 1 : -1) : 0;
                oneResult.trend = trendArrowImages[trend === 1 ? "high" : trend === -1 ? "low" : ""]
                result.push(oneResult)
            }
        }
        if (_widgetScope?.dateFilterValue?.value === "Quarter") {
            for (let i = 0; i < Object.keys(groupData).length; i++) {
                let oneData = groupData[Object.keys(groupData)[i]]
                let oneResult = {
                    product: oneData[0].product,
                    count: 0
                }
                const months = Array(3).fill({})
                let monthValue = moment(_widgetScope.dateFilterValueFormatted.fromDate).month()
                months.forEach(async (m, index) => {
                    let count = oneData.find(check => moment(check.date).month() === monthValue)
                    count = count?.count || 0
                    oneResult['month' + (index + 1)] = count
                    oneResult.count = oneResult.count + count
                    monthValue = monthValue + 1
                })
                let prevCount = previous.find(prevData => prevData.product === oneData[0].product)
                let trend = prevCount?.count ? (oneResult.count === prevCount.count ? 0 : oneResult.count > prevCount.count ? 1 : -1) : 0;
                oneResult.trend = trendArrowImages[trend === 1 ? "high" : trend === -1 ? "low" : ""]
                result.push(oneResult)
            }
        }
        if (_widgetScope?.dateFilterValue?.value === "Year") {
            for (let i = 0; i < Object.keys(groupData).length; i++) {
                let oneData = groupData[Object.keys(groupData)[i]]
                let oneResult = {
                    product: oneData[0].product,
                    count: 0
                }
                const quarters = [[1, 2, 3], [4, 5, 6], [7, 8, 9], [10, 11, 12]]
                quarters.forEach(async (m, index) => {
                    let count = oneData.find(check => m.includes(moment(check.date).month()))
                    count = count?.count || 0
                    oneResult['q' + (index + 1)] = count
                    oneResult.count = oneResult.count + count
                })
                let prevCount = previous.find(prevData => prevData.product === oneData[0].product)
                let trend = prevCount?.count ? (oneResult.count === prevCount.count ? 0 : oneResult.count > prevCount.count ? 1 : -1) : 0;
                oneResult.trend = trendArrowImages[trend === 1 ? "high" : trend === -1 ? "low" : ""]
                result.push(oneResult)
            }
        }
    }
    if (!data.length && !previous.length) return []
    return result
}

export default clientFunctions;